<template>
 <div class="animated fadeIn">
   <div class="row">
     <div class="col-sm-6 col-md-4">
       <b-card>
         <picture-input
           ref="pictureInput"
           width="200"
           height="200"
           margin="16"
           accept="image/jpeg,image/png"
           size="10"
           buttonClass="btn btn-primary button"
           :removable="true"
           :customStrings="{
             drag: 'click here to upload picture'
           }"></picture-input>
         <table class="table3">
           <tr>
             <td>ID</td>
             <td>{{rowData.id}}</td>
           </tr>
           <tr>
             <td>{{ $t('message.machineName') }}</td>
             <td><b-form-input type="text" v-model="rowData.name" :disabled="!isAdmin(role_id)"></b-form-input></td>
           </tr>
           <tr>
             <td>{{ $t('message.machineSerial') }}</td>
             <td><b-form-input type="text" v-model="rowData.serial" :disabled="!isAdmin(role_id)"></b-form-input></td>
           </tr>
           <tr v-if="isAdmin(role_id)">
             <td>{{ $t('message.simSerial') }}</td>
             <td>
               <b-form-select 
                 v-model="rowData.simId" 
                 :options="simList" 
                 :disabled="!isAdmin(role_id)" 
                 text-field="serial"
                 value-field="id">
               </b-form-select>
             </td>
           </tr>
           <tr v-if="isAdmin(role_id)">
             <td>{{ $t('message.gsmSerial') }}</td>
             <td><b-form-input type="text" v-model="rowData.gsmSerial" :disabled="!isAdmin(role_id)"></b-form-input></td>
           </tr>
           <tr v-if="isAdmin(role_id)">
             <td>{{ $t('message.kioskOwner') }}</td>
             <td>
               <b-form-select 
                 v-model="rowData.userId" 
                 :options="userList" 
                 :disabled="!isAdmin(role_id)" 
                 text-field="userName"
                  value-field="userId">
               </b-form-select>
             </td>
           </tr>
           <tr>
             <td>{{ $t('message.status') }}</td>
             <template v-if="now - new Date(rowData.lastOnline) <= 1800000"><td><span class="badge badge-success" style="font-size: 100%">{{ $t('message.online') }}</span></td></template>
             <template v-else><td><span class="badge badge-danger" style="font-size: 100%">{{ $t('message.offline') }}</span></td></template>
           </tr>
           <tr>
             <td>{{ $t('message.machineModel') }}</td>
             <td>{{rowData.model.name}}</td>
           </tr>
           <tr v-if="isAdmin(role_id)">
             <td>{{ $t('message.description') }}</td>
             <td>{{rowData.model.description}}</td>
           </tr>
           <tr v-if="isAdmin(role_id)">
             <td>{{ $t('message.slot') }}</td>
             <td>{{rowData.slotAmount}}</td>
           </tr>
           <!-- <tr v-if="isAdmin(role_id)">
             <td>{{ $t('message.fee') }} (%)</td>
             <td>
                <b-form-select v-model="rowData.deductCredit.deductCreditType" :options="$t('message.arrays.fee')" class="mb-3" :disabled="!isAdmin(role_id)"></b-form-select>
              <div v-if="rowData.deductCredit.deductCreditType === 'AMOUNT_PER_UNIT'">
                <b-form-input v-model.number="rowData.deductCredit.deductCreditValue"  type="number"></b-form-input>
              </div>
             </td>
           </tr> -->
           <!-- <tr v-if="isAdmin(role_id)">
             <td>Request password</td>
             <td><c-switch type="text" variant="pill" label color="success" size="lg" v-model="rowData.rqPassword" :disabled="!isAdmin(role_id)"/></td>
           </tr> -->
         </table>
       </b-card>
         
       <b-card header="Configs">
         <table class="table3">
           <thead>
             <th></th>
             <th>NAME</th>
             <th>VALUE</th>
           </thead>
           <tr v-if="isAdmin(role_id)">
             <td>1</td>
             <td style="text-align: left">REQUEST PASSWORD</td>
             <td><c-switch type="text" variant="pill" label color="success" size="lg" v-model="rowData.rqPassword" :disabled="!isAdmin(role_id)"/></td>
           </tr>
           <template v-for="(items, index) in rowData.configs">
             <tr>
               <td v-if="isAdmin(role_id)">{{index+2}}</td>
               <td v-if="!isAdmin(role_id)">{{index+1}}</td>
               <td style="text-align: left">{{items.name}}</td>
               <template v-if="items.type === 'BOOL'">
                  <td><c-switch type="text" variant="pill" label color="success" size="lg" v-model="items.value" :disabled="!isAdmin(role_id)"/></td>
               </template>
               <template v-else-if="items.type === 'NUMBER'"> 
                 <td width="50%"><b-form-input type="number" v-model="items.value " :disabled="!isAdmin(role_id)" min="0" /></td>
               </template>
             </tr>
           </template>
         </table>
       </b-card>

       <b-card header="Statuses" v-if="isAdmin(role_id)">
         <table class="table3">
           <thead>
             <th>NAME</th>
             <th>VALUE</th>
           </thead>
           <template v-for="items in rowData.statuses">
             <tr>
               <template v-if="items.name === 'LOCATION'">
                 <td>{{items.name}}</td>
                 <td><b-button @click="showMap()" variant="primary" >Show</b-button></td>
               </template>
               <template v-else>
                 <td>{{items.name}}</td>
                 <template v-if="items.type === 'JSON' && items.value.status === 'available'">
                   <td>
                     <b-btn id="exPopover" variant="outline-success" size="sm">{{items.value.status}}</b-btn>
                   </td>
                 </template>
                 <template v-else-if="items.type === 'JSON' && items.value.status === 'error'">
                   <td>
                     <div>
                       <b-popover
                         target="exPopover"
                         :triggers="triggers"
                         :content="items.value.details"
                         placement="right">
                         <b-btn id="exPopover" variant="outline-danger" size="sm">  {{items.value.status}}</b-btn>
                       </b-popover>
                     </div>
                   </td>

                 </template>
                 <template v-else-if="items.name === 'FILTER_DETAILS'">
                   <td><b-button variant="warning" size="sm" @click="showFilterDetails(JSON.parse(items.value))">แสดงรายละเอียด</b-button></td>
                   
                   <!-- {{items.value}} -->
                     <!-- <tr>{{item}}</tr> -->
                     <!-- <tr>
                       <td>filter id</td>
                       <td>{{item.id}}</td>
                     </tr>
                     <tr>
                       <td>วันที่เปลี่ยนล่าสุด</td>
                       <td>{{item.lastChange}}</td>
                     </tr>
                     <tr>
                       <td>ปริมาณน้ำที่ไหลผ่าน</td>
                       <td>{{item.qty}}</td>
                     </tr> -->
                 </template>
                 <template v-else>
                   <td>{{items.value}}</td>
                 </template>
               </template>
             </tr>
           </template>
         </table>
       </b-card>



     </div>
     <div class="col-md-8">
       <b-card header="Money inbox">
         <table class="table-modal" style="text-align: center">
           <thead style="text-align-last: center">
             <th>{{ $t('message.type') }}</th>
             <th>{{ $t('message.amount') }}</th>
             <th>{{ $t('message.balance') }}</th>
           </thead>
           <tbody>
             <tr><th>{{ $t('message.coin') }}</th><td></td><td></td></tr>
             <template v-for="(value, key) in rowData.inbox.coin">
               <tr>
                 <td>{{key}} {{ $t('message.currency') }}</td>
                 <td>{{value}}</td>
                 <td>{{value*key}} {{ $t('message.currency') }}</td>
               </tr>
             </template>
             <tr><th>{{ $t('message.banknote') }}</th><td></td><td></td></tr>
             <template v-for="(value, key) in rowData.inbox.bill">
               <tr>
                 <td>{{key}} {{ $t('message.currency') }}</td>
                 <td>{{value}}</td>
                 <td>{{value*key}} {{ $t('message.currency') }}</td>
               </tr>
             </template>
           </tbody>
           <tfoot style="text-align-last: center">
             <td></td>
             <th>{{ $t('message.sum') }}</th>
             <th>{{rowData.inbox.total}} {{ $t('message.currency') }}</th>
           </tfoot>
         </table>
       </b-card>
       <b-card header="Coin Acceptor" v-if="isAdmin(role_id)">
         <table class="table3">
           <th colspan="5">Model {{rowData.coinAcceptor.model}}
             <b-button @click="showCoin()" variant="warning" size="sm" :disabled="!isAdmin(role_id)">{{ $t('message.changeCoinAcceptor') }}</b-button>
           </th>
           <tr>
             <th>{{ $t('message.type') }}</th>
             <th>1 {{ $t('message.currency') }}</th>
             <th>2 {{ $t('message.currency') }}</th>
             <th>5 {{ $t('message.currency') }}</th>
             <th>10 {{ $t('message.currency') }}</th>
           </tr>
           <template v-for="items in rowData.coinAcceptor.configs">
             <tr>
               <td>{{items.name}}</td>
               <template v-for="data in items.value">
                 <td>
                   <template v-if="data.type === 'int'">
                     <b-form-input type="number" v-model.number="data.value" size="sm" :disabled="!isAdmin(role_id)"/>
                   </template>
                   <template v-else-if="data.type == 'bool'" >
                     <td><c-switch type="text" variant="pill" label color="success" size="lg" v-model="data.value" :disabled="!isAdmin(role_id)"/></td>
                   </template>
                 </td>
               </template>
             </tr>
           </template>
           </tr>
         </table>
       </b-card>
     <b-modal ref="changeModelCoinAcc" hide-header @ok="changeModelCoin()" ok-title="ยืนยัน" close-title="ยกเลิก">
       <p style="color: red">คำเตือน!! เมื่อเลือกโมดูลใหม่และกดยืนยันแล้วจะไม่สามารถนำข้อมูลเดิมกลับมาได้</p>
       <table class="table3">
         <tr>{{ $t('message.selectCoinAcceptor') }}</tr>
         <tr>
           <td><b-form-select
             v-model="selectedCoinAcceptor"
             :options="coinAcceptorList"
             class="mb-3"
             text-field="model"
             value-field="value"
            /></td>
         </tr>
       </table>
     </b-modal>

       <b-card header="Bill Acceptor" v-if="isAdmin(role_id)">
         <table class="table3">
           <th colspan="5">Model {{rowData.billAcceptor.model}}
             <b-button @click="showBill()" variant="warning" size="sm" :disabled="!isAdmin(role_id)">{{ $t('message.changeBanknoteAcceptor') }}</b-button>
           </th>
           <tr>
             <th>{{ $t('message.type') }}</th>
             <th>20 {{ $t('message.currency') }}</th>
             <th>50 {{ $t('message.currency') }}</th>
             <th>100 {{ $t('message.currency') }}</th>
             <th>500 {{ $t('message.currency') }}</th>
             <th>1000 {{ $t('message.currency') }}</th>
           </tr>
           <template v-for="items in rowData.billAcceptor.configs">
             <tr>
               <td>{{items.name}}</td>
               <template v-for="data in items.value">
                 <td>
                   <template v-if="data.type == 'int'"><b-form-input type="number" v-model.number="data.value" size="sm" :disabled="!isAdmin(role_id)"/></template>
                   <template v-else-if="data.type == 'bool'">
                     <td><c-switch type="text" variant="pill" label color="success" size="lg" v-model="data.value" :disabled="!isAdmin(role_id)"/></td>
                   </template>
                 </td>
               </template>
             </tr>
           </template>
           </tr>
         </table>
       </b-card>
     </div>
     <b-modal ref="changeModelBillAcc" hide-header @ok="changeModelBill()" ok-title="ยืนยัน" close-title="ยกเลิก">
       <p style="color: red">คำเตือน!! เมื่อเลือกโมดูลใหม่และกดยืนยันแล้วจะไม่สามารถนำข้อมูลเดิมกลับมาได้</p>
       <table class="table3">
         <tr>{{ $t('message.selectBanknoteAcceptor') }}</tr>
         <tr>
           <td><b-form-select
             v-model="selectedBillAcceptor"
             :options="billAcceptorList"
             class="mb-3"
             text-field="model"
             value-field="value"
            /></td>
         </tr>
       </table>
     </b-modal>
     <b-modal ref="mapModal" hide-header hide-footer size="lg">
        <template v-if="locationData.lat && locationData.lon">
          <gmap-map
            :center="locationData"
            :zoom="18"
            style="width: 770px; height: 600px"
          >
          <gmap-marker
            :position="locationData"
            :clickable="true"
            :draggable="true"
            ></gmap-marker>
          </gmap-map>
        </template>
     </b-modal>
   </div>

   <!-- start kan -->
     <b-card header="Devices">
     <div>
       <b-btn v-on:click="showModalDevice" variant="success" :disabled="!isAdmin(role_id)">{{ $t('message.addDevice') }}</b-btn>&nbsp;
     </div><hr>
     <template>
     <div class="table-responsive">
     <table class="table2">
         <thead>
             <th>ID</th>
             <th>{{ $t('message.enable') }}</th>
             <th>{{ $t('message.status') }}</th>
             <th>{{ $t('message.machineType') }}</th>
             <th>{{ $t('message.size') }}</th>
             <th>{{ $t('message.setting') }}</th>
         </thead>
         <tbody>
             
             <template v-for="d in rowData.devices">
                 <tr>
                     <td>{{d.id}}</td>
                     <!-- <td valign="middle"><c-switch type="text" variant="pill" label color="success" size="lg" v-model="d.enable" :disabled="!isDealer(role_id)"/></td> -->
                     <td valign="middle"><c-switch type="text" variant="pill" label color="success" size="lg" v-model="d.enable"/></td>
                     <template v-if="(d.status) === 'WASHING'"><td><span class="badge badge-warning" style="font-size: 100%">{{ $t('message.washing') }}</span></td></template>
                     <template v-else-if="(d.status) === 'READY'"><td><span class="badge badge-success" style="font-size: 100%">{{ $t('message.ready') }}</span></td></template>
                     <template v-else-if="(d.status) === 'ERROR'"><td><span class="badge badge-danger" style="font-size: 100%">{{ $t('message.machineError') }}</span></td></template>
                     <td>
                         <b-form-select
                             v-model="d.deviceType"
                             :options="deviceType"
                             text-field="name"
                             value-field="typeId"
                             :disabled="!isAdmin(role_id)"
                         />
                     </td>
                     <td width="15%">
                       <b-form-input type="text" v-model="d.description" :disabled="!isAdmin(role_id)"/>
                     </td>
                     <td>
                         <b-btn variant="warning" @click="showModal(d)">{{ $t('message.setting') }}</b-btn>&nbsp;
                         <b-btn v-if="isAdmin(role_id)" variant="danger" @click="showModalDeleteDevice(d)" :disabled="!isAdmin(role_id)">{{ $t('message.delete') }}</b-btn>
                     </td>
                 </tr>
             </template>
         </tbody>
     </table>
     </div>
   </template>
   </b-card>

   <div>
             <!-- Modal Component -->
            <b-modal ref="myModalRef" size="lg" hide-header @ok="createNewSlots()" :ok-title="$t('message.save')" :cancel-title="$t('message.cancel')" @cancel="getMachineDetails()">
                <template>
                    <b-button v-if="isAdmin(role_id)" variant="info" @click="addLineSlots()" :disabled="!isAdmin(role_id)">{{ $t('message.add') }}</b-button>&nbsp;
                    <hr>
                    <template v-if="modalData.deviceDetails.type !== 'board'">
                        <thead align="center">
                            <th>{{ $t('message.slot') }}</th>
                            <th>{{ $t('message.price') }}</th>
                            <th v-if="modalData.deviceDetails.type === 'vending'">{{ $t('message.amount') }}</th> 
                            <th v-if="isAdmin(role_id)">{{ $t('message.enable') }}</th>
                            <th>{{ $t('message.product') }}</th>
                        </thead>
                        <tr align="center" v-for="(items, index, key) in modalData.slots">
                            <td width=10%><b-form-input type="number" v-model.number="items.slotId" :disabled="!isAdmin(role_id)"/></td>
                            <td width=10% ><b-form-input type="number" v-model.number="items.price"/></td>
                            <td width=10% v-if="modalData.deviceDetails.type === 'vending'"><b-form-input type="number" v-model.number="items.qty"/></td>
                            <td valign="middle" width=10% v-if="isAdmin(role_id)"><c-switch type="text" variant="pill" label color="success" size="lg" v-model="items.enable"/></td>
                            <td><b-form-select
                                v-model.number="items.productId"
                                :options="productList"
                                text-field="name"
                                value-field="id"
                                :disabled="(!isAdmin(role_id) && modalData.deviceDetails.type !== 'vending')"
                            /></td>
                            <td v-if="isAdmin(role_id)"><b-button variant="danger" @click="removeLineSlots(items)" :disabled="index === 0 || !isAdmin(role_id)">{{ $t('message.delete') }}</b-button></td>
                        </tr>
                    </template>
                    <template v-else>
                      <div class="table-responsive">
                        <table class="table2">
                            <thead style="text-align-last: center">
                                <th>{{ $t('message.slot') }}</th>
                                <th>{{ $t('message.note') }}</th>
                                <template v-for="(item, index, key) in modalData.slots">
                                    <template v-if="item.config" v-for="(items, indexConfig , key) in item.config">
                                        <th v-if="index === 0" colspan="2">{{ $t('message.period') }} {{indexConfig+1}}</th>
                                    </template>
                                </template>
                            </thead>
                            <tr align="center" v-for="(items, index, key) in modalData.slots">
                                <td width="10%"><b-form-input type="number" v-model.number="items.slotId"/></td>
                                <td><b-form-input type="text" v-model="items.note"/></td>
                                <!-- <template v-if="Array.isArray(items.config)"> -->
                                    <template v-for="item in items.config" >
                                        <td><datetime type="time" v-model="item.start" input-class="form-control" format="HH:mm"></datetime></td>
                                        <td><datetime type="time" v-model="item.stop" input-class="form-control" format="HH:mm"></datetime></td>
                                    </template>
                                    <template v-if="items.config.length < 4">
                                        <td colspan="2"><b-btn variant="success" size="sm" @click="addTime(items)">{{ $t('message.addTime') }}</b-btn></td>
                                    </template>
                                <!-- </template> -->
                                <!-- <template v-else>
                                    <td colspan="2"><b-btn variant="success" size="sm" @click="addTime(items)">{{ $t('message.addTime') }}</b-btn></td>
                                </template> -->
                                <!-- <td><b-button variant="danger" size="sm" @click="removeLineSlots(items)" :disabled="index === 0 || !isAdmin(role_id)">{{ $t('message.delete') }}</b-button></td> -->
                            </tr>
                        </table>
                      </div>
                  </template>
              </template>
            </b-modal>
         </div>

   <b-modal ref="modalDeleteDevice" size="sm" @ok="deleteDevice()" hide-header :ok-title="$t('message.confirm')" :cancel-title="$t('message.back')">
     <table class="table-modal">
      <tr>
        <td>{{ $t('message.machineId') }} </td>
        <td style="color: red">{{modalDataDeleteDevice.machineId}}</td>
      </tr>
      <tr>
        <td>{{ $t('message.deviceId') }} </td>
        <td style="color: red">{{modalDataDeleteDevice.id}}</td>
      </tr>
    </table>

   </b-modal>

   <b-modal ref="modalFilterDetails" size="md" hide-header>
     <table class="table-modal">
       <tr style="text-align: center">
         <th>Filter ID</th>
         <th>วันที่เปลี่ยนล่าสุด</th>
         <th>ปริมาณน้ำที่ไหลผ่าน</th>
       </tr>
       <template v-for="items in modalFilterDetailsData">
         <tr style="text-align: center">
           <td>{{items.id}}</td>
           <td>{{items.lastChange}}</td>
           <td>{{items.qty}} ลิตร</td>
         </tr>
       </template>
    </table>

   </b-modal>

   <!-- Modal Component -->
   <b-modal ref="myModalRefDevice" size="lg"  hide-header @ok="createDevices()" @hide="getMachineDetails()"  :ok-title="$t('message.create')" :cancel-title="$t('message.cancel')" >
   <template >
       <div>
       <b-button variant="info" @click="addLine">{{ $t('message.add') }}</b-button>&nbsp;
       <b-button variant="info" @click="removeLine">{{ $t('message.delete') }}</b-button>&nbsp;
     <hr>
       <b-row class="text-center"  v-for="line in lines">
         <b-col cols="2"><b-form-input
                           v-model="line.id"
                           type="text"
                           placeholder="id" /></br>
         </b-col>
         <b-col cols="10">  <b-form-select
                             v-model="line.deviceType"
                             :options="deviceType"
                             text-field="name"
                             value-field="typeId"/>
         </b-col>
       </b-row>
       </div>
   </template>
   </b-modal>
   <!-- end kan -->

   <b-card>
     <div align="right">
       <b-button @click="goBack()" variant="danger" size="lg">{{ $t('message.back') }}</b-button>&nbsp;
       <!-- <b-button @click="confirm()" variant="primary" size="lg" >บันทึก</b-button> -->
       <b-button @click="updateNewMachine()" variant="primary" size="lg">{{ $t('message.save') }}</b-button>
     </div>
   </b-card>
   <!-- <b-modal ref="confirmModal" hide-header size="sm" @ok="saveSlots()">
     Press OK to save
   </b-modal> -->
 </div>
</template>

<script>
import { Switch as cSwitch } from '@coreui/vue'
import webServices from '../../script'
import PictureInput from 'vue-picture-input'
import VueGoogleMaps from 'vue2-google-maps'
import { ModelListSelect } from '../../custom_modules/search-select'
export default {
    name: 'setting',
    components: {
        cSwitch,
        PictureInput,
        VueGoogleMaps,
        ModelListSelect
      },
    data () {
      return {
        test: '',
        now: new Date(),
        role_id: window.localStorage.getItem('roleID'),
        userId: window.localStorage.getItem('userId'),
            id: this.$route.params.id,
            productList: [],
            coinAcceptorList: [],
            billAcceptorList: [],
            selectedCoinAcceptor: {
                model: '',
                configs: ''
            },
            statuses: [],
            selectedBillAcceptor: {},
            rowData: {
                id: this.$route.params.id,
                name: '', 
                serial: '',
                mode: '',
                userId: '',
                slotAmount: '',
                coinAcceptor: {},
                billAcceptor: {},
                slots: [],
                model: {
                    type: {
                        name: ''
                    }
                },
                configs: [],
                statuses: [],
                image: '',
                inbox: {
                    coin: '',
                    bill: '',
                    total: ''
                },
                deductCredit: ''
                },
            productArg: {
                page: 1,
                rows: 50,
                sku: ''
            },
            locationData: {
              lat: 0,
              lng: 0
            },
            machineType: '',
            triggers: ['hover'],
            newSlots: [],
            //kan
            deviceType: [],
            modalData: {
                deviceDetails: {}
            },
            modalDataDevice: [],
            modalDataDeleteDevice: {},
            modalFilterDetailsData: [],
            lines: [],
            selected: null,
            options: [
                { value: 'AMOUNT_PER_UNIT', text: 'หักค่าทำเนียม' },
                { value: 'NONE', text: 'ไม่หักค่าทำเนียม' },
            ],  
            //end kan
            simList: [],
            userList: []
        }
    },
    methods: {
        isAdmin (role) {
            return webServices.isAdmin(role)
        },
        isDealer (role) {
            return webServices.isDealer(role)
        },
        getMachineDetails () {
            this.$Progress.start()
            webServices.getMachineProfileV2(this.id).then((res) => {
                this.$Progress.finish()
                // if (res.data.machine.userId !== this.userId && this.role_id !== 'admin') {
                //     this.$toast.error({
                //       title: 'ERROR',
                //       message: 'คุณไม่ได้รับอนุญาติให้เข้าถึงหน้านี้'
                //     })
                //     this.goBack()
                // } else {
                    this.rowData = res.data
                      this.rowData.configs.forEach((val) => {
                      this.rowData.model.configs.forEach((model) => {
                        if (val.name === model.name) {
                          val.type = model.type
                        }
                      })
                    })
                    for (var i = 0; i < this.rowData.configs.length; i++) {
                      
                        if ((this.rowData.configs[i].value === 'true' || this.rowData.configs[i].value === '1') && this.rowData.configs[i].type === 'BOOL') {
                          // console.log(this.rowData.configs[i].value)
                            this.rowData.configs[i].value = true
                        } else if ((this.rowData.configs[i].value === 'false' || this.rowData.configs[i].value === '0') && this.rowData.configs[i].type === 'BOOL') {
                          // console.log(this.rowData.configs[i].value)
                            this.rowData.configs[i].value = false
                        }
                    }

                    const configs = []
                    this.rowData.configs.forEach((val) => {
                      if (val.name === 'ENABLE') {
                        configs[0] = val
                      } else if (val.name === 'RESET') {
                        configs[1] = val
                      } else if (val.name === 'SMS') {
                        configs[2] = val
                      } else if (val.name === 'CASH PAYMENT') {
                        configs[3] = val
                      } else if (val.name === 'PROMPT PAY') {
                        configs[4] = val
                      } else if (val.name === 'PAYMENT MENU PAGE') {
                        configs[5] = val
                      } else if (val.name === 'MENU WASHER') {
                        configs[6] = val
                      } else if (val.name === 'MENU DRY') {
                        configs[7] = val
                      } else if (val.name === 'MENU ADD CREDIT') {
                        configs[8] = val
                      } else if (val.name === 'MENU SALE') {
                        configs[9] = val
                      } else if (val.name === 'MENU LAUNDRY SERVICE') {
                        configs[10] = val
                      } else if (val.name === 'MENU USER SETTING') {
                        configs[11] = val
                      } else if (val.name === 'USER RESET PASSWORD') {
                        configs[12] = val
                      } 
                    })
                    if (this.rowData.model.type === 'washing') {
                      this.rowData.configs = configs
                    }
                    
                    // for (i = 0; i < this.rowData.slots.length; i++) {
                    //   this.rowData.slots[i].productId = this.rowData.slots[i].product.id
                    // }
                    for (var i = 0; i < this.rowData.statuses.length; i++) {
                        if (this.rowData.statuses[i].name === 'LOCATION') {
                            this.locationData = this.rowData.statuses[i].value
                        }
                    }
                    this.machineType = res.data.model.type.name
                    this.changeStatus()
                    this.getDeviceType()
                // }
                
            }).catch((err) => {
                this.$Progress.fail()
                console.log('ERROR @MACHINE DETAIL')
                console.log(err)
                this.$toast.error({
                  title: 'ERROR',
                  message: 'เกิดข้อผิดพลาด'
                }) 
                if (err.response.status === 401) {
                    webServices.tokenExpire()
                }
            })
        },
        getProduct () {
            //console.log('get product')
            let params = {
              type: this.machineType,
              productType: this.modalData.deviceDetails.type === 'washer' ? 'washer' : this.modalData.deviceDetails.type === 'dryer' ? 'dryer,dryer_extension_tm' : (this.machineType === 'washing' && this.modalData.deviceDetails.type === 'vending') ? `softener,detergent` : ''
            }
            webServices.getProductList(params).then((res) => {
                // console.log(res.data)
                this.productList = res.data
            }).catch((err) => {
                console.log('ERROR @PRODUCT')
                console.log(err)
                this.$toast.error({
                  title: 'ERROR',
                  message: 'เกิดข้อผิดพลาด'
                }) 
                if (err.response.status === 401) {
                    webServices.tokenExpire()
                }
            })
        },
        getCoin () {
            webServices.getCoin().then((res) => {
                this.coinAcceptorList = res.data
                for (var i = 0; i < this.coinAcceptorList.length; i++) {
                    this.coinAcceptorList[i].model = this.coinAcceptorList[i].value.model
                }
            }).catch((err) => {
                console.log('ERROR @COIN')
                console.log(err)
                this.$toast.error({
                  title: 'ERROR',
                  message: 'เกิดข้อผิดพลาด'
                }) 
                if (err.response.status === 401) {
                    webServices.tokenExpire()
                }
            })
        },
        getBill () {
            webServices.getBill().then((res) => {
                this.billAcceptorList = res.data
                for (var i = 0; i < this.billAcceptorList.length; i++) {
                    this.billAcceptorList[i].model = this.billAcceptorList[i].value.model
                }
            }).catch((err) => {
                console.log('ERROR @BILL')
                console.log(err)
                this.$toast.error({
                  title: 'ERROR',
                  message: 'เกิดข้อผิดพลาด'
                }) 
                if (err.response.status === 401) {
                    webServices.tokenExpire()
                }
            })
        },
        changeModelCoin () {
            this.rowData.coinAcceptor.model = this.selectedCoinAcceptor.model
            this.rowData.coinAcceptor.configs = this.selectedCoinAcceptor.configs
        },
        showCoin () {
            this.$refs.changeModelCoinAcc.show()
        },
        changeModelBill () {
            this.rowData.billAcceptor.model = this.selectedBillAcceptor.model
            this.rowData.billAcceptor.configs = this.selectedBillAcceptor.configs
        },
        showBill () {
            this.$refs.changeModelBillAcc.show()
        },
        showMap (data) {
            this.$refs.mapModal.show()
        },
        changeStatus () {
            this.statuses = this.rowData.statuses
            for (var i = 0; i < this.statuses.length; i++) {
                if (this.statuses[i].define && this.statuses[i].type === 'NUMBER' && this.statuses[i].value !== null) {
                    var status = this.statuses[i].value.toString()
                    this.statuses[i].value = this.statuses[i].define[status]
                }
            }
        },
        goBack () {
            window.history.back()
        },
        confirm () {
            this.$refs.confirmModal.show()
        },
        getDeviceType () {
            webServices.getDeviceType({kiosktype: this.machineType}).then((res) => {
                this.deviceType = res.data
                //console.log(this.deviceType)
            }).catch((err) => {
                console.log(err)
                this.$toast.error({
                  title: 'ERROR',
                  message: 'เกิดข้อผิดพลาด'
                }) 
                if (err.response.status === 401) {
                    webServices.tokenExpire()
                }
            })
        },
        showModal (data) {
            this.modalData = data
            this.$refs.myModalRef.show()
            this.getProduct()
        },
        showModalDevice (data) {
            this.modalDataDevice = data
            this.$refs.myModalRefDevice.show()
            //  console.log(this.modalDataDevice)
        },
        showModalDeleteDevice (data) {
          this.modalDataDeleteDevice = data
          this.$refs.modalDeleteDevice.show()
        },
        showFilterDetails (data) {
          this.modalFilterDetailsData = data
          this.$refs.modalFilterDetails.show()
        },
        deleteDevice () {
          const { machineId, deviceIndex, id} = this.modalDataDeleteDevice
          const body = {
            machineId: machineId,
            deviceIndex: deviceIndex,
            id: id
          }
          webServices.deleteDevice(body).then((res) => {
            if (res.data.success) {
              this.$toast.success({
                title: 'SUCCESS',
                message: 'device has been deleted'
              })
            } else {
              const error = webServices.showError(res.data)
              this.$toast.error({
                title: 'ERROR',
                message: JSON.stringify(error)
              })
            }
            this.getMachineDetails()
          }).catch((error) => {
            this.$Progress.fail()
            this.$toast.error({
              title: 'ERROR',
              message: 'เกิดข้อผิดพลาด'
            }) 
            if (err.response.status === 401) {
              webServices.tokenExpire()
            }
          })
        },
        addLine () {
            let lastItem = Object.assign({}, this.rowData.devices[this.rowData.devices.length - 1])
            lastItem.id++
            delete lastItem.deviceIndex
            this.lines.push(lastItem)
            this.rowData.devices.push(lastItem)
        },
        removeLine () {
            this.lines.pop()
        },
        addLineSlots () {
            let lastItem = Object.assign({}, this.modalData.slots[this.modalData.slots.length - 1])
            lastItem.slotId++
            lastItem.config = [{
                start: '00:00',
                stop: '00:00'
              },{
                start: '00:00',
                stop: '00:00'
              },{
                start: '00:00',
                stop: '00:00'
              },{
                start: '00:00',
                stop: '00:00'
              }]
            this.modalData.slots.push(lastItem)
            this.newSlots.push(lastItem)
        },
        removeLineSlots (slots) {
          const { machineId, deviceId, slotId } = slots
          const slot = {
            machineId: machineId,
            deviceId: deviceId,
            slotId: slotId
          }
            webServices.deleteSlots(slot).then((res) => {
              if (res.data.success) {
              this.$toast.success({
                title: 'SUCCESS',
                message: 'slot has been deleted'
              })
            } else {
              const error = webServices.showError(res.data)
              this.$toast.error({
                title: 'ERROR',
                message: JSON.stringify(error)
              })
            }
            this.getMachineDetails()
            this.modalData.slots = this.modalData.slots.filter (el => el.slotId !== slotId)
          }).catch((error) => {
            this.$Progress.fail()
            this.$toast.error({
              title: 'ERROR',
              message: 'เกิดข้อผิดพลาด'
            }) 
            if (err.response.status === 401) {
              webServices.tokenExpire()
            }
          })
        },
        addTime (slot) {
            if (!slot.config) {
                slot.config = []  
            }
            if (slot.config.length > 0) {
              slot.config.push({
                start: '00:00',
                stop: '00:00'
              })
            } else {
              slot.config.push({
                start: '00:00',
                stop: '00:00'
              },{
                start: '00:00',
                stop: '00:00'
              },{
                start: '00:00',
                stop: '00:00'
              },{
                start: '00:00',
                stop: '00:00'
              })
            }
            
        },
        createDevices () {
            this.$Progress.start()
            webServices.createDevices(this.lines).then((res) => {
                this.$Progress.finish()
                // console.log(res.data)
                if (res.data.success) {
                    //location.reload();
                    this.$toast.success({
                    title: 'SUCCESS',
                        message: 'device has been created'
                    })
                } else {
                    const error = webServices.showError(res.data)
                    this.$toast.error({
                        title: 'ERROR',
                        message: JSON.stringify(error)
                    })
                }
                this.lines = []
                this.getMachineDetails()
            }).catch((error) => {
                this.$Progress.fail()
                this.$toast.error({
                  title: 'ERROR',
                  message: 'เกิดข้อผิดพลาด'
                }) 
                if (err.response.status === 401) {
                    webServices.tokenExpire()
                }
            })
        },
        createNewSlots () {
            this.$Progress.start()
            webServices.updateSlots(this.modalData.slots).then((resp) => {
                this.$Progress.finish()
                if (resp.data.success) {
                    if (this.newSlots.length > 0) {
                        webServices.createSlots(this.newSlots).then((res) => {
                            if (res.data.success) {
                                //location.reload();
                                this.$toast.success({
                                    title: 'SUCCESS',
                                    message: 'สำเร็จ'
                                })
                            } else {
                                const error = webServices.showError(res.data)
                                this.$toast.error({
                                    title: 'ERROR',
                                    message: JSON.stringify(error)
                                })
                            }
                            this.getMachineDetails()
                            // this.updateNewMachine()
                        }).catch((error) => {
                            this.$Progress.fail()
                            console.log(error)
                            this.$toast.error({
                              title: 'ERROR',
                              message: 'เกิดข้อผิดพลาด'
                            }) 
                            if (err.response.status === 401) {
                                webServices.tokenExpire()
                            }
                            this.getMachineDetails()
                        })
                    }
                    this.$toast.success({
                      title: 'SUCCESS',
                      message: 'บันทึกสำเร็จ'
                    })
                    this.newSlots = []
                } else {
                    this.getMachineDetails()
                    const errorText = {
                        errorCode: resp.data.errorCode,
                        errorText: resp.data.errorText
                    }
                    this.$toast.error({
                        title: 'ERROR',
                        message: JSON.stringify(errorText)
                    })
                }
            }).catch((err) => {
                this.$Progress.fail()
                console.log(err)
                this.$toast.error({
                  title: 'ERROR',
                  message: 'เกิดข้อผิดพลาด'
                }) 
                if (err.response.status === 401) {
                    webServices.tokenExpire()
                }
            })

        },
        updateNewMachine(){
            this.$Progress.start()
            // for (var i = 0; i < this.rowData.configs.length; i++) {
            //   if (this.rowData.configs[i].value === true || this.rowData.configs[i].value === '1') {
            //     this.rowData.configs[i].value = 'true'
            //   } else if (this.rowData.configs[i].value === false || this.rowData.configs[i].value === '0') {
            //     this.rowData.configs[i].value = 'false'
            //   }
            // }
            webServices.updateMachineV2(this.rowData).then((res) => {
                this.$Progress.finish()
                if (res.data.success) {
                    // console.log('success')
                    var data = {
                        machineId: this.id,
                        cmdId: 1
                    }
                    webServices.setCommands(data).then((resp) => {
                        this.$toast.success({
                            title: 'SUCCESS',
                            message: 'Machine has been updated'
                        })
                        // console.log(resp.data)
                        // console.log('set commands')
                        window.history.back()
                    }).catch((err) => {
                        console.log('error @COMMANDS')
                        console.log(err)
                        this.$toast.error({
                          title: 'ERROR',
                          message: 'เกิดข้อผิดพลาด'
                        }) 
                        if (err.response.status === 401) {
                            webServices.tokenExpire()
                        }
                        this.getMachineDetails()
                    })
                } else {
                    this.getMachineDetails()
                    const error = webServices.showError(res.data)
                    this.$toast.error({
                        title: 'ERROR',
                        message: JSON.stringify(error)
                    })
                }
                
            }).catch((err) => {
                this.getMachineDetails()
                this.$Progress.fail()
                console.log(err)
                this.$toast.error({
                  title: 'ERROR',
                  message: 'เกิดข้อผิดพลาด'
                }) 
                if (err.response.status === 401) {
                    webServices.tokenExpire()
                }
            })
        },
        getSim () {
          webServices.getSim().then((res) => {
            this.simList = res.data
          }).catch((err) => {
            console.log(err)
            console.log('error @sim')
            const errorText = {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(errorText)
            })
          })
        },
        getUserList () {
          webServices.getUserList('all').then((res) => {
            this.userList = res.data
          }).catch((err) => {
            console.log(err)
            console.log('error @user')
            const errorText = {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(errorText)
            })
          })
        },
    },
    mounted () {
        if (this.id !== '') {
            this.getMachineDetails()
            this.getCoin()
            this.getBill()
            this.getSim()
            this.getUserList()
        }
    },
}
</script>

<style lang="scss">
 .table3 {
   width: 100%;
   border: none;
 }
 .table3 td, .table3 th {
   padding: 5px;
   vertical-align: middle;
   text-align: center;
 }
</style>
